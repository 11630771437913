import Typewriter from "typewriter-effect";

const TypeWriter = ({ staticText, typedText }) => {
  return (
    <div className="typewriter">
      <div className="staticText">{staticText} </div>
      <div className="typedText">
        <h1>
          <Typewriter
            options={{
              strings: [...typedText],
              autoStart: true,
              loop: true,
            }}
          />
        </h1>
      </div>
    </div>
  );
};

export default TypeWriter;
