import React from "react";

const Pyramid = ({ isPyramidOpen }) => {
  return (
    <div className={`pyramid-base ${isPyramidOpen ? "active" : ""}`}>
      <div className="triangle side-1"></div>
      <div className="triangle side-2"></div>
      <div className="triangle side-3"></div>
      <div className="triangle side-4"></div>
      <div className="shadow"></div>
    </div>
  );
};

export default Pyramid;
