import { useState, useEffect } from "react";
import Tilty from "react-tilty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faCodepen,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  /* To disable hover while the paper-plane is flying, add a timer
  and make the paper-planes have a disabled class, and add pointer-events: none;
  Once the time is up, meaning the paper-planes landed, remove the
  disabled class
  */
  const [isPlane12Flying, setPlane12Flying] = useState(true);
  const [isPlane34Flying, setPlane34Flying] = useState(true);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setPlane12Flying(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, []);

  useEffect(() => {
    const timer2 = setTimeout(() => {
      setPlane34Flying(false);
    }, 3000);
    return () => clearTimeout(timer2);
  }, []);

  return (
    <div className="contact">
      <div className="contact-container">
        <div className="contact-message">
          I’m currently seeking full-time/freelance opportunities. So if you are
          interested to hire, collaborate or simply chat over a cup of coffee
          don’t hesitate to get in touch!
          <p className="how-to-email">
            (Just click on one of the paper planes to send me an email!)
          </p>
        </div>
        <div className="contact-email">hi@salahakil.com</div>
        <div className="contact-socials">
          <a id="github" href="https://github.com/Salah-Akil">
            <p className="logo icon-github-circled">
              <FontAwesomeIcon icon={faGithub} />
            </p>
          </a>
          <a id="linkedin" href="https://www.linkedin.com/in/salah-akil/">
            <p className="logo icon-linkedin">
              <FontAwesomeIcon icon={faLinkedin} />
            </p>
          </a>
          <a id="codepen" href="https://codepen.io/salah-akil">
            <p className="logo icon-codepen">
              <FontAwesomeIcon icon={faCodepen} />
            </p>
          </a>
        </div>
      </div>
      <div className="pp-container">
        <a href="mailto:hi@salahakil.com">
          <Tilty
            className={`tilt tilt-pp1 ${
              isPlane12Flying ? "paperplane-flying" : ""
            }`}
            scale={1.2}
            perspective={200}
            settings={{
              gyroscope: true,
              gyroscopeMinAngleY: 0,
              gyroscopeMaxAngleY: 90,
            }}
          >
            <img
              className="paperplane"
              id="tilt-pp1"
              src={"/assets/img/pp1.png"}
              alt="paperplane"
            />
          </Tilty>
        </a>
        <a href="mailto:hi@salahakil.com">
          <Tilty
            className={`tilt tilt-pp2 ${
              isPlane12Flying ? "paperplane-flying" : ""
            }`}
            scale={1.2}
            perspective={150}
            settings={{
              gyroscope: true,
              gyroscopeMinAngleY: 0,
              gyroscopeMaxAngleY: 90,
            }}
          >
            <img
              className="paperplane"
              id="tilt-pp2"
              src={"/assets/img/pp2.png"}
              alt="paperplane"
            />
          </Tilty>
        </a>
        <a href="mailto:hi@salahakil.com">
          <Tilty
            className={`tilt tilt-pp3 ${
              isPlane34Flying ? "paperplane-flying" : ""
            }`}
            scale={1.2}
            perspective={100}
            settings={{
              gyroscope: true,
              gyroscopeMinAngleY: 0,
              gyroscopeMaxAngleY: 90,
            }}
          >
            <img
              className="paperplane"
              id="tilt-pp3"
              src={"/assets/img/pp3.png"}
              alt="paperplane"
            />
          </Tilty>
        </a>
        <a href="mailto:hi@salahakil.com">
          <Tilty
            className={`tilt tilt-pp4 ${
              isPlane34Flying ? "paperplane-flying" : ""
            }`}
            scale={1.3}
            perspective={130}
            settings={{
              gyroscope: true,
              gyroscopeMinAngleY: 0,
              gyroscopeMaxAngleY: 90,
            }}
          >
            <img
              className="paperplane"
              id="tilt-pp4"
              src={"/assets/img/pp4.png"}
              alt="paperplane"
            />
          </Tilty>
        </a>
      </div>
    </div>
  );
};

export default Contact;
