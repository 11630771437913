import React from "react";
import TypeWriter from "../components/TypeWriter";

const Home = () => {
  return (
    <div className="home">
      <TypeWriter
        staticText={
          <h1 className="static-p">
            Hi There!
            <br />
            My Name is Salah!
            <br />
            And I truly enjoy
          </h1>
        }
        typedText={[
          "Creating Stuff!",
          "Designing Products!",
          "Solving Problems!",
          // "a good cup of Coffee!",
        ]}
      />
    </div>
  );
};

export default Home;
