import React, { useState, useEffect } from "react";
import Pyramid from "./Pyramid";

const Navigation = () => {
  const PAGES = {
    home: "/",
    about: "/about",
    skills: "/skills",
    contact: "/contact",
  };

  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isPyramidOpen, setIsPyramidOpen] = useState(false);

  useEffect(() => {
    function updatePyramid() {
      if (isPyramidOpen !== isMenuActive) {
        setIsPyramidOpen(isMenuActive);
      }
    }

    updatePyramid();
    // eslint-disable-next-line
  }, [isPyramidOpen]);

  const togglePyramid = () => {
    if (!isPyramidOpen) {
      setIsPyramidOpen(true);
    } else {
      setTimeout(() => {
        setIsPyramidOpen(false);
      }, 900);
    }
  };

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
    togglePyramid();
  };

  const onClick = (event, href) => {
    if (event.metaKey || event.ctrlKey) {
      return;
    }

    event.preventDefault();
    window.history.pushState({}, "", href);

    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
    toggleMenu();
  };

  return (
    <div className="navigation">
      <div className="pyramid-container">
        <a href="#!" className="pyramid" onClick={toggleMenu}>
          <Pyramid isPyramidOpen={isPyramidOpen} />
        </a>
      </div>
      <div className={`menu ${isMenuActive ? "opened" : ""}`}>
        <div className={`menu-list ${isMenuActive ? "opened" : ""}`}>
          <a
            onClick={(e) => onClick(e, PAGES.home)}
            href={PAGES.home}
            className="menu-button home"
          >
            Home
          </a>
          <a
            onClick={(e) => onClick(e, PAGES.about)}
            href={PAGES.about}
            className="menu-button"
          >
            About
          </a>
          {/* <a
            onClick={(e) => onClick(e, PAGES.skills)}
            href={PAGES.skills}
            className="menu-button"
          >
            Skills
          </a> */}
          <a
            onClick={(e) => onClick(e, PAGES.contact)}
            href={PAGES.contact}
            className="menu-button"
          >
            Contact
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
