import { useState } from "react";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";
import Marker from "../components/Marker";

const About = () => {
  const { width, height } = useWindowSize();
  const [party, setParty] = useState(false);

  const colorsConfetti = [
    "#ff9298",
    "#e4008e",
    "#ff008d",
    "#734b6d",
    // Comment all below for minimal colors
    "#835eff",
    "#fff2b3",
    "#4f4f4f",
    "#6dd5ed",
    "#ffafbd ",
    "#bdc3c7 ",
    "#2c3e50",
    "#de6262 ",
    "#ffb88c",
    "#06beb6 ",
    "#516395",
    "#e29587",
    "#ddd6f3 ",
  ];

  const confetti = (
    <Confetti
      className="confetti"
      width={width}
      height={height}
      recycle={false}
      style={{ pointerEvents: "none" }}
      numberOfPieces={party ? 500 : 0}
      colors={colorsConfetti}
      onConfettiComplete={(confetti) => {
        setParty(false);
        confetti.reset();
      }}
    />
  );

  return (
    <div className="about">
      <div className="about-container">
        <div className="about-message">
          I'm a <Marker text={"Software Engineer"} delay={1500} />
          &nbsp; from the beautiful country of Italy. My interest in coding
          started in High School and by the time I graduated I pursued a{" "}
          <Marker text={"Bachelor"} delay={2400} />
          &nbsp;then a <Marker text={"Master's degree"} delay={3300} /> in
          Computer Science {" "}
          <a href="#!" onClick={() => setParty(!party)} className="party-time">
            <img
              className="confetti-emoji"
              src={"/assets/img/emoji_u1f389.svg"}
              alt="confetti"
            ></img>
          </a>
          <br />
          <br />
          Besides <Marker text={"Computer Science,"} delay={4200} />
          &nbsp; I have a strong interest in{" "}
          <Marker text={"Social Psychology,"} delay={5100} />
          &nbsp; <Marker text={"Marketing,"} delay={6000} />
          &nbsp; and <Marker text={"UI/UX Design,"} delay={6900} />
          &nbsp; and I'm fascinated by how combining all these four subjects can
          help us understand the customer's needs and create a beautiful and
          meaningful product.
          <br />
          <br />
          <span id="popConfetti">Have you popped the confetti?</span>
        </div>
      </div>
      {confetti}
    </div>
  );
};

export default About;
