import React, { useEffect, useState } from "react";

const Marker = ({ text, delay = 1000 }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, delay);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  return (
    <span className="marker">
      <span className={animate ? "marker-highlight" : ""}>{text}</span>
    </span>
  );
};

export default Marker;
