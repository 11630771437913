import Footer from "./Footer";
import Home from "../pages/Home";
import About from "../pages/About";
import Skills from "../pages/Skills";
import Contact from "../pages/Contact";
import Navigation from "./Navigation";
import Route from "./Route";
import LavaLamp from "./LavaLamp";

const App = () => {
  return (
    <div className="container-grid">
      <Navigation />
      <div className="main-content">
        <Route path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/skills">
          <Skills />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
      </div>
      <Footer />
      <LavaLamp />
    </div>
  );
};

export default App;
